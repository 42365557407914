import React, { useEffect } from "react";

import { useLocation, useNavigate } from 'react-router-dom';

import { useStoreon } from 'storeon/react';

import { ExternalProps } from "../interfaces";

const AuthorizePage = () => {
  const { search } = useLocation();
  const { dispatch } = useStoreon("external");
  const navigate = useNavigate();

  useEffect(() => {
    const tmp = search.substring(1)
    let p:any | ExternalProps = {}
    tmp.split('&').forEach(item => {
        const t = item.split('=')
        p[t[0]] = decodeURIComponent(t[1]);
    })
    if (p.client_id && p.redirect_uri) {
        dispatch('clear')
        dispatch('setExternal', p)
        navigate("/signin");
    }
    
  }, [search, dispatch, navigate]);

  return (
    <div className="flex flex-col items-center w-full">
        w8 4 it
    </div>
  );
};

export default AuthorizePage;
