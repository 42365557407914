import React, { useEffect, useState } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'

import { useStoreon } from 'storeon/react';

import * as Pages from "../pages";

const AuthRouter = () => {
  const { external } = useStoreon('external');
  const { dispatch, token } = useStoreon('token');
  const [ tokenCopy, setTokenCopy ] = useState<any>({});

  useEffect(() => {
    if (external !== null && !isEmpty(token)) {
      const tmp = cloneDeep(token)
      setTokenCopy(tmp)
    }
  },[external, token, setTokenCopy])

  useEffect(()=>{
    if (!isEmpty(token) && !isEmpty(tokenCopy) ) {
      dispatch('set', {})
    }

  },[dispatch, token, tokenCopy])

  useEffect(()=>{
    if (external !== null && !isEmpty(tokenCopy) && isEmpty(token) && window) {
      let params = "";
      Object.keys(tokenCopy).forEach((item:string) => {
        params = params.concat(`${item}=${tokenCopy[item]}&`);
      })
      const url =  `${external.redirect_uri}?${params.slice(0,-1)}` ;
      window.location.href = url;
    }
  },[external, tokenCopy, token])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Pages.SignIn />} />
        <Route path="signin" element={<Pages.SignIn />} />
        <Route path="signup" element={<Pages.SignUp />} />
        <Route path="reset" element={<Pages.ResetPage />} />
        <Route path="signup-success" element={<Pages.SignUpSucessPage />} />
        <Route path="signup-failed" element={<Pages.SignUpFailedPage />} />
        <Route path="change_password" element={<Pages.ChangePasswordPage />} />
        <Route path="authorize" element={<Pages.AuthorizePage />} />
        <Route path="refresh" element={<Pages.RefreshPage />} />
        {/* <Route path='logout' element={handleLogout} /> */}
        {/* <Route path='authorize' element={handleAuthorize} /> */}
      </Routes>
    </BrowserRouter>
  )
}

export default AuthRouter