import React, { useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom'
import { useStoreon } from "storeon/react";

import { Block, UI } from "@etnamed/ui-kit/dist";

import { useRequests } from '../hooks'

const SignIn = () => {
  const { token } = useStoreon("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token.access_token !== undefined) {
      navigate('/')
    }
  }, [token, navigate])

  const { signInByPhone, signInByEmail, sendOTP } = useRequests()

  const Logo = useCallback(() => <UI.Logo link="/" />, []);

  return (
    <div className="flex flex-col items-center w-full">
      <Block.SignInBlock
        LogoComponent={Logo}
        signUpPageLink="/signup"
        recoveryPageLink="/reset"
        signInByPhone={signInByPhone}
        signInByEmail={signInByEmail}
        sendOTP={sendOTP}
      />
    </div>
  );
};

export default SignIn;
