import React, { useCallback, useEffect } from "react";

import { useLocation } from 'react-router-dom';

import { useStoreon } from 'storeon/react';

import isEmpty from 'lodash/isEmpty';

import { ExternalRefreshProps, TokenProps } from "../interfaces";

import { useRequests } from '../hooks';

const RefreshPage = () => {
  const { search } = useLocation();
  const { dispatch } = useStoreon("external");
  const { refreshToken } = useRequests();

  const sendFailureRefreshToken = useCallback(( redirect_uri:string)=>{
    window.location.href = redirect_uri;
  },[])

  const sendRefreshedToken = useCallback((token: TokenProps|any, redirect_uri:string) => {
    if (!isEmpty(token) && window) {
      let params = "";
      Object.keys(token).forEach((item:string) => {
        params = params.concat(`${item}=${token[item]}&`);
      })
      const url =  `${redirect_uri}?${params.slice(0,-1)}` ;
      window.location.href = url;
    }
  },[])
  
  useEffect(() => {
    const tmp = search.substring(1)
    let p:any | ExternalRefreshProps = {}
    tmp.split('&').forEach(item => {
        const t = item.split('=')
        p[t[0]] = decodeURIComponent(t[1]);
    })
    if (p.client_id && p.refresh_token && p.redirect_uri_on_success && p.redirect_uri_on_failure) {
        refreshToken(p.client_id, p.refresh_token)
        .then((result)=>{
          if (result?.ok && result?.token) {
            sendRefreshedToken(result.token, p.redirect_uri_on_success);
          } else {
            sendFailureRefreshToken(p.redirect_uri_on_failure);
          }
        })
        .catch((e)=>{
          console.log("Error on refresh token:", e)
          sendFailureRefreshToken(p.redirect_uri_on_failure);
        })
        
    }
  }, [search, dispatch, refreshToken, sendRefreshedToken, sendFailureRefreshToken]);

  return (
    <div className="flex flex-col items-center w-full">
        w8 4 it
    </div>
  );
};

export default RefreshPage;
