import React from 'react';
import { Flex } from 'rebass'

import { Router } from './services'

function App() {
  return (
    <Flex minHeight="100vh" py={24} bg="#ECF8F9" flexDirection="column" justifyContent="flex-start" alignItems="center">
      <Router />
    </Flex>
  );
}

export default App;
