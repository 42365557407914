import React from 'react';
import ReactDOM from 'react-dom';
import '@etnamed/ui-kit/dist/ui-kit.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StoreContext } from 'storeon/react'
import { store } from './store'

ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <App />
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
