import { createStoreon, StoreonModule } from "storeon";

import { ExternalProps, TokenProps } from '../interfaces'

interface AuthProps {
    token: TokenProps,
    external: ExternalProps | null,
}

interface Events {
  clear: unknown;
  set: TokenProps;
  setExternal: ExternalProps;
}

const initState = {
    token: {},
    external: null,
};

const authStore: StoreonModule<AuthProps, Events> = (store) => {
  store.on("@init", () =>   (initState));
  store.on("clear", (oldState) => ({oldState,token:{}}));
  store.on("set", (state, newToken) => {
    return {token: newToken};
  });
  store.on("setExternal", (state, newExternal) => {
    console.log("zxcxzcccc")
    return {external: newExternal};
  });
};

export const store = createStoreon<AuthProps, Events>([
  authStore,
]);
